<template>
  <div class="container pd100">
    <div v-if="!searchLoading">
      <div class="header">
        <div class="header-top">
          <van-image class="header-avatar" :src="teacher.teacher_avatar" />
          <div class="header-name">{{ teacher.teacher_name }}</div>
        </div>
        <div class="header-bottom">
          <div class="header-bottom-inner">
            <div class="header-txt">{{ teacher.teacher_class_count }}</div>
            <div class="header-title">教课次数</div>
          </div>
          <div class="header-bottom-inner">
            <div class="header-txt">{{ teacher.teacher_class_count }}</div>
            <div class="header-title">教学人数</div>
          </div>
        </div>
      </div>
      <schedule v-model="active" class="mb10" :weeks="weeks" @change="onScheduleChange">
        <template #right>
          <div class="campus" @click.stop="onCampusShow">
            <van-icon class="mr5" class-prefix="icon" name="MapOutline1" />
            <div class="campus-title">{{ campus.label }}</div>
          </div>
        </template>
        <div v-if="list.length" class="list">
          <div v-for="item in list" :key="item.book_id" class="list-item van-hairline--bottom">
            <div class="list-item-h2">{{ item.course_title }}</div>
            <div class="list-item-title">校区：{{ item.studio_name }}</div>
            <div class="list-item-title">教室：{{ item.room_name }}</div>
            <div class="list-item-title">老师：{{ item.teacher }}</div>
            <div class="list-item-title">时间：{{ item.class_date }} {{ item.class_time }}</div>
            <div class="list-item-arrow" @click="onNavigator({path:'/student/schedule/detail?book_id='+item.course_class_id})">详情<van-icon name="arrow" /></div>
            <div class="list-item-action">
              <van-button type="primary" size="small" :to="'/student/ask-leave?book_id='+item.course_class_id">我要请假</van-button>
            </div>
          </div>
        </div>
        <div v-else class="no-data">
          <img :src="no_order" class="no-data-image">
          <div class="no-data-title">暂无排课</div>
        </div>
      </schedule>
      <div class="studio mb10">
        <van-cell title="授课校区" />
        <div class="studio-list">
          <div v-for="item in teacher_belong_studio" :key="item.studio_id" class="studio-list-item van-hairline--bottom" @click="onNavigator({path:'/studio-detail?id='+item.studio_id})">
            <div class="studio-list-item-hd">{{ item.studio_name }}</div>
            <div class="studio-list-item-bd"><van-icon name="arrow" color="#969799" size="16" /></div>
          </div>
          <div v-show="showFull">
            <div v-for="item in studioList" :key="item.studio_id" class="studio-list-item van-hairline--bottom" @click="onNavigator({path:'/studio-detail?id='+item.studio_id})">
              <div class="studio-list-item-hd">{{ item.studio_name }}</div>
              <div class="studio-list-item-bd"><van-icon name="arrow" color="#969799" size="16" /></div>
            </div>
          </div>
          <div class="load-more" @click="onLoadMore">{{ showFull ? '收起' : '展开更多' }}<van-icon :name="showFull ? 'arrow-up' : 'arrow-down'" class="ml5" color="#969799" size="16" /></div>
        </div>
      </div>
      <div class="teacer-images mb10">
        <van-cell title="老师图片" />
        <div class="teacer-images-inner">
          <div v-for="(item,index) in teacher_images" :key="index" class="teacer-images-item" @click="onPreview(index)">
            <van-image class="teacer-images-img" :src="item.thumb" />
          </div>
        </div>
      </div>
      <div class="teacer-desc">
        <van-cell title="老师介绍" />
        <div class="teacer-desc-inner">
          <div class="content" v-html="content" />
        </div>
      </div>
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          value-key="label"
          @cancel="showPicker = false"
          @confirm="onConfirm"
        />
      </van-popup>
      <div class="affix-bar">
        <van-button type="primary" block :to="'/audition?studio_id='+104">预约试听</van-button>
      </div>
    </div>
    <loadings :loading='searchLoading' />
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import Schedule from '@/components/Schedule'
import dayjs from 'dayjs'
import no_order from '@/assets/no_order.png'
import { Picker, ImagePreview } from 'vant'
import Loadings from '@/components/loading'

export default {
  name: 'TeacherDetail',
  components: {
    schedule: Schedule,
    [Picker.name]: Picker,
    loadings:Loadings,
  },
  mixins: [PageMixin],
  data() {
    return {
      teacher: {},
      teacher_images: [],
      teacher_belong_studio: [],
      studioList: [],
      showFull: false,
      searchLoading:false,
      content: '',
      campus: { label: '全部校区', value: -1 },
      showPicker: false,
      columns: [],
      //
      list: [],
      active: dayjs().format('YYYY-MM-DD'),
      weeks: [],
      no_order
    }
  },
  created() {
    this.onList()
    this.onDetail()
  },
  methods: {
    onDetail() {
      this.searchLoading = true
      this.$api.teacher_detail({ teacher_id: this.$route.query.teacher_id }).then(res => {
        this.teacher = res.data.teacher
        this.teacher_images = res.data.teacher.teacher_images
        this.teacher_belong_studio = res.data.teacher.teacher_belong_studio.slice(0, 5)
        if (res.data.teacher.teacher_belong_studio.length > 5) {
          this.studioList = res.data.teacher.teacher_belong_studio.slice(5, res.data.teacher.teacher_belong_studio.length)
        }
        this.columns = res.data.teacher.teacher_belong_studio.map(item => ({ label: item.studio_name, value: item.studio_id }))
        this.content = res.data.teacher.teacher_description || ''
        this.searchLoading = false
      })
    },
    onLoadMore() {
      this.showFull = !this.showFull
    },
    onPreview(index) {
      const images = this.teacher_images.map(item => item.image)
      WeixinJSBridge.invoke("imagePreview", {
          "urls":images,
          "current": images[index]
      })
    },
    onCampusShow() {
      this.showPicker = true
    },
    onCampusClose() {
      this.showPicker = false
    },
    onConfirm(value) {
      this.showPicker = false
      this.campus = value
      this.onList()
    },
    initWeeks(item) {
      return {
        id: '',
        date: item.date,
        txt: item.txt,
        txtDate: item.txt_date,
        dot: item.courses && item.courses.length
      }
    },
    onList() {
      const params = {}
      this.campus.value > -1 && (params.studio_id = this.campus.value)
      params.teacher_id = this.$route.query.teacher_id
      params.date = this.active
      this.$api.teacher_schedule(params).then(res => {
        this.weeks = res.data.schdule_list.map(item => this.initWeeks(item))
        const current = res.data.schdule_list.find(item => item.date === this.active) || {}

        this.list = current.courses
      })
    },
    onScheduleChange(value) {
      this.onList()
    }
  }
}
</script>
<style lang="less" scoped>

  .header{
    box-sizing: border-box;
    width: 100%;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: @linear-gradient-color;
    font-size: 14px;
    color: #fff;
    &-avatar{
      width: 80px;
      height: 80px;
      overflow: hidden;
      border-radius: 50%;
    }
    &-name{
      font-size: 16px;
      color: #fff;
    }
    &-top{
      text-align: center;
      margin-bottom: 5px;
    }
    &-bottom{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &-inner{
        width: 50%;
        text-align: center;
      }
    }
  }

  .studio-list{
    background: #fff;
    &-item{
      box-sizing: border-box;
      padding: 12px 15px;
      font-size: 14px;
      color: #333;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &-hd{
        flex: 1;
      }
    }
  }

  .load-more{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #222;
    text-align: center;
  }
  .teacer-images{
    background: #fff;
    &-inner{
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow-x: auto;
    }
    &-item{
      flex-shrink: 0;
      margin-right: 10px;

    }
    &-img{
      width: 100px;
      height: 100px;
    }
  }

  .teacer-desc{
    background: #fff;
    margin-bottom: 10px;
    &-inner{
      padding: 10px 15px;
    }
    &-img{
      width: 100px;
      height: 100px;
    }
  }
  // 课表
  .campus{
    display: flex;
    align-items: center;
    justify-self: start;
    color: @ui-color;

  }

  .list{
    background: #fff;
    min-height: 220px;
  }
  .list-item{
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 180px;
    padding: 20px 15px;
    &-h2{
      color: #333;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &-title{
      color: #666;
      font-size: 14px;
      margin-bottom: 6px;
    }
    &-arrow{
      position: absolute;
      top: 19px;
      right: 15px;
      color: #999;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-self: start;
    }
    &-action{
      position: absolute;
      bottom: 19px;
      right: 15px;
    }
  }

  .no-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-sizing: border-box;
    min-height: 220px;
    text-align: center;
    &-image{
      width: 96px;
      height: 96px;
      background: #fff;
      margin-bottom: 10px;
    }

  }
</style>

